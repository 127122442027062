export const assignPageVars = async (page, fullMode) => {
  const items = page.content
  const numItems = items.length
  let vars = {
    title: page.title,
    subtitle: '',
    mainImage: '',
    text: '',
    excerpt: page.excerpt,
    date: page.created,
    hasSubtitle: false,
    hasMainImage: false,
    hasGallery: false,
    extra: '',
    hasExtra: false
  }
  let addToExtra = false
  for (let i = 0; i < numItems; i++) {
    let item = items[i]
    if (item.type) {
      if (!addToExtra) {
        switch (item.type) {
          case 'h2':
            if (i < 4 && !vars.hasSubtitle) {
              vars.hasSubtitle = true
              vars.subtitle = item.html
            } else {
              vars.text += item.html
            }
            break
          case 'image':
          case 'gallery':
            if (!vars.hasMainImage) {
              vars.hasMainImage = true
              vars.mainImage = item.html
              if (item.type === 'gallery') {
                vars.hasGallery = true;
              }
            } else {
              addToExtra = true
            }
            break
          case 'plain':
          case 'h3':
            vars.text += item.html
            break
          case 'embed':
            addToExtra = true
            break;
        }
      }
    }
    if (addToExtra) {
      vars.extra += item.html
      vars.hasExtra = true
    }
  }
  if (fullMode === true) {
    reassignLongTitle(vars)
  }
  return vars
}

const reassignLongTitle = (vars) => {
  const maxTitleLength = vars.hasMainImage ? vars.hasGallery ? 96 : 64 : 32;
  if (vars.title.length > (maxTitleLength - 12)) {
    if (!vars.subtitle.length < 3) {
      let fullTitle = vars.title;
      let maxLen = fullTitle.length > maxTitleLength ? maxTitleLength : fullTitle.length;
      let words = fullTitle.substring(0, maxLen).split(' ');
      words.pop();
      vars.title = words.join(' ') + ' ...';
      vars.subtitle = fullTitle;
      vars.hasSubtitle = true
    }
  }
}

export const applyPageContent = async (slug, pages) => {
  if (pages instanceof Array) {
    let page = pages.find(p => p.slug === slug)
    if (page) {
      if (page.title) {
        if (page.content instanceof Array) {
          return assignPageVars(page);
        }
      }
    }
  }
  return { valid: false }
}
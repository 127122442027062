import React, { Component } from 'react'
import MenuList from './menu-list'

class Footer extends Component {
  render() {
    const { title, subtitle, logos, menuItems, current, creditLinks } = this.props.data
    return (
      <footer className="footer">
        <section className="bottom">
          <div className="inner-wrapper">
            <div className="inner">
              <h2>{subtitle}</h2>
              <div className="logos">
                {logos.length > 0 &&
                  logos.map((img, index) => (
                    <figure className="logo" key={index}>
                      <img src={img.uri} alt={img.title} className={img.className} />
                      <figcaption>{img.title}</figcaption>
                    </figure>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <section className="top inner">
          <div className="inner-wrapper">
            <div className="inner">
              <h2 className="side-title">{title}</h2>
              <MenuList
                items={menuItems}
                current={current}
                className="footer-menu"
              />
              <ul className="menu credit-links">
                  {creditLinks.map((link, index) => (
                    <li key={index}><a href={link.to} target="_blank">{link.content}</a></li>
                  ))}
              </ul>
            </div>
          </div>
        </section>
      </footer>
    )
  }
}

export default Footer

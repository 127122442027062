export const isNumeric = (num) => {
	let dt = typeof num;
	if (num !== null && num !== undefined && (dt === 'number' || dt === 'string')) {
		return !isNaN(parseFloat(num)) && isFinite(num);	
	} else {
		return false;
	}
}


export const matchInt = (param, defaultValue) => {
	if (!isNumeric(param)) {
    return defaultValue;
  } else {
    return parseInt(param);
  }
}

export const addBodyClass = (className, addMode) => {
	let cl = document.body.classList
	let exists = cl.contains(className)
	if (addMode !== false) {
		if (!exists) {
			cl.add(className)
		}
	} else {
		if (exists) {
			cl.remove(className)
		}
	}
}

export const removeBodyClass = (className) => {
	addBodyClass(className, false)
}


export const transformLocal = (img, params) => {
  const { width, height } = params
  const parts = img.uri.split('.');
  const ext = parts.pop();
  const baseImgUri = parts.join('.')
  return `${baseImgUri}--${width}--${height}.${ext}?t=${width}`
}

export const transformCloudinary = (img, cloudinary, params) => {
  const { width, height, crop, quality } = params
  return `${cloudinary.baseUrl}/${
    cloudinary.widget.cloudName
    }/image/upload/f_auto,q_auto/c_${crop},h_${height},q_${quality},w_${width}/v1/${
    img.publicId
    }.jpg`
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MenuListItem extends Component {
  isActive = path => {
    if (typeof path === 'string') {
      let secPath = path.split('/').find(part => part.length > 1)
      if (!secPath) {
        secPath = ''
      }
      const section = '/' + secPath
      return this.props.current === section
    }
    return false
  }

  render() {
    const { to, children } = this.props
    const itemClasses = this.isActive(to) ? 'active' : 'inactive'
    const linkClasses = to.replace(/^\//, '').replace('/', '-')
    return (
      <li className={itemClasses}>
        <Link to={to} className={linkClasses}>
          {children}
        </Link>
      </li>
    )
  }
}

export default MenuListItem

import React from 'react'
import { Segment } from 'semantic-ui-react'
import Photo from './photo'
import PropTypes from 'prop-types'

const matchMinIndexRefs = (arrNums, numCols) => {
	let indexMap = {
		index: 0,
		height: 0
	}
	if (arrNums instanceof Array && arrNums.length > 0) {
		indexMap.height = Math.min(...arrNums)
		indexMap.index = arrNums.findIndex(v => v === indexMap.height)
		if (indexMap.index < 0 || indexMap.index >= numCols) {
			indexMap.index = 0
		}
	}
	return indexMap
}

class Grid extends React.Component {
  sorting = false
  init = true

  state = {
    numCols: 6,
    currTotal: 0,
    sortedImages: [],
    colHeights: []
  }

  componentWillMount = async () => {
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    let numImgs = this.props.images.length
    let { currTotal } = prevState
    let restart =
      (this.props.restart && this.props.restart !== prevProps.restart) ||
      this.init === true
    if (numImgs > currTotal || restart) {
      if (!this.sorting) {
        this.reorder(restart)
        this.init = false
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  calcNumCols = () => {
    let masonryCont = document.getElementById('masonry-grid')
    let recalc = false
    let cols = this.state.numCols
    if (masonryCont) {
      let nc = this.state.numCols
      let ww = window.innerWidth
      let { maxCols, colWidth } = this.props
      cols = Math.floor(ww / colWidth)
      if (cols > maxCols) {
        cols = maxCols
      }
      if (cols < 1) {
        cols = 1
      }
      recalc = nc !== cols
      if (recalc) {
        this.setState({
          numCols: cols
        })
      }
    }

    return {
      num: cols,
      changed: recalc
    }
  }

  reorder = reset => {
    this.sorting = true
    let numCols = this.calcNumCols().num
    let gridImgs = []
    if (reset !== true) {
      gridImgs = this.state.sortedImages
    }
    let colHeights = this.state.colHeights
    let imgs = this.props.images
    let numImgs = imgs.length
    let totMapped = 0
    if (reset !== true) {
      for (let i = 0; i < numCols; i++) {
        if (i < gridImgs.length) {
          if (gridImgs[i] instanceof Array) {
            totMapped += gridImgs[i].length
          }
        }
      }
    }
    if (totMapped < 1) {
      gridImgs = []
      colHeights = []
      for (let i = 0; i < numCols; i++) {
        gridImgs.push([])
        colHeights.push(0)
      }
    }
    for (let i = totMapped; i < numImgs; i++) {
      //let colIndex = i % numCols
      let minH = matchMinIndexRefs(colHeights, numCols)
      if (gridImgs[minH.index]) {
        colHeights[minH.index] += imgs[i].ratio
        gridImgs[minH.index].push(imgs[i])
        // colHeights[colIndex] += imgs[i].ratio;
        // gridImgs[colIndex].push(imgs[i])
      }
    }
    this.setState({
      sortedImages: gridImgs,
      currTotal: numImgs,
      colHeights: colHeights
    })
    setTimeout(() => {
      this.sorting = false
    }, 250)
  }

  handleResize = () => {
    if (this.calcNumCols().changed) {
      if (!this.sorting) {
        setTimeout(() => this.reorder(true), 50)
      }
    }
  }

  setMain = img => {
    this.props.setMain(img)
  }

  render() {
    let { sortedImages, numCols } = this.state
    let { photoSize } = this.props
    let numImages = this.props.images.length
    let photoSizeHeight = photoSize * 5
    return (
      <Segment.Group id="masonry-grid" horizontal className={`cols-${numCols}`}>
        {numImages > 0 &&
          sortedImages.map((col, colIndex) => (
            <div className="column" key={colIndex}>
              {col.map((img, index) => (
                <Segment
                  as="figure"
                  key={img._id}
                  onClick={() => this.setMain(img)}
                  className={`index-${img.index}`}>
                  <Photo
                    img={img}
                    width={photoSize}
                    height={photoSizeHeight}
                    className="preview"
                    lazy={img.index > 48}
                  />
                  <figcaption>
                    <p className="caption">{img.caption}</p>
                    <p className="full-name">{img.user.displayName}</p>
                  </figcaption>
                </Segment>
              ))}
            </div>
          ))}
      </Segment.Group>
    )
  }
}

Grid.propTypes = {
  images: PropTypes.array,
  restart: PropTypes.bool,
  setMain: PropTypes.func,
  maxCols: PropTypes.number,
  colWidth: PropTypes.number,
  photoSize: PropTypes.number
}

Grid.defaultProps = {
  images: [],
  restart: false,
  maxCols: 6,
  colWidth: 220,
  photoSize: 400
}

export default Grid

import React, { Component } from 'react'
import MenuListItem from './menu-list-item'
import { Icon } from 'semantic-ui-react'

class MenuList extends Component {
  state = {
    items: []
  }

  async componentDidMount() {
    this.processItems()
  }

  processItems = () => {
    let menuItems = this.props.items.map(item => {
      item.hasContent = typeof item.content === 'string'
      item.hasLabel = typeof item.label === 'string'
      item.hasLoggedinLabel = typeof item.loggedinLabel == 'string'
      item.hasIcon = typeof item.icon === 'string'
      return item
    })
    this.setState({ items: menuItems })
  }

  render() {
    let { current, className } = this.props
    let { items } = this.state
    const hasItems = items instanceof Array && items.length > 0
    let cls = ['menu']
    if (className) {
      cls.push(className)
    }

    const listClasses = cls.join(' ')
    return (
      <ul className={listClasses}>
        {hasItems &&
          items.map((item, index) => (
            <MenuListItem to={item.to} current={current} key={index}>
              {item.hasContent && item.content}
              {item.hasLabel && (
                <span
                  className={item.hasLoggedinLabel ? `logged-out` : `label`}>
                  {item.label}
                </span>
              )}
              {item.hasLoggedinLabel && (
                <span className="logged-in">{item.loggedinLabel}</span>
              )}
              {item.hasIcon && <Icon className={item.icon} />}
            </MenuListItem>
          ))}
      </ul>
    )
  }
}

export default MenuList

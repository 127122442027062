import React from 'react'
import { Container } from 'semantic-ui-react'
import renderHTML from 'react-render-html'
import { fetchPosts } from '../../modules/api'
import { assignPageVars } from '../../modules/cms'
import moment from 'moment'
import PropTypes from 'prop-types'

const HeadingOne = ({ title, aside }) => {
  let cls = ['title']
  if (aside) {
    cls.push('aside')
  }
  let titleClassNames = cls.join(' ')
  return <h1 className={titleClassNames}>{title}</h1>
}

class Page extends React.Component {
  state = {
    title: 'Page title',
    text: '',
    date: null,
    showDate: false,
    hasMainImage: false,
    hasSubtitle: false,
    subtitle: '',
    mainImage: null,
    extra: '',
    hasExtra: false
  }

  async componentWillMount() {
    const { pages, slug, location } = this.props
    let page = null
    if (slug && pages instanceof Array) {
      let alias = ''
      switch (slug) {
        case '--info':
          alias = window.location.pathname.split('/').pop()
          break
        default:
          alias = slug
          break
      }
      page = pages.find(p => p.slug === alias)
    } else {
      let postData = await fetchPosts()
      if (postData.valid) {
        let slug = location.pathname.split('/').pop()
        page = postData.items.find(p => p.slug === slug)
        this.setState({ showDate: true })
      }
    }
    if (page) {
      if (page.title) {
        if (page.content instanceof Array) {
          assignPageVars(page, true).then(vars => {
            this.setState(vars)
          })
        }
      }
    }
  }

  render() {
    const {
      title,
      subtitle,
      date,
      showDate,
      text,
      hasMainImage,
      hasSubtitle,
      mainImage,
      extra,
      hasExtra
    } = this.state
    let hasDate = showDate && typeof date === 'string' && /\d+-\d/.test(date)
    let cls = ['text-content']
    const textContentClass = cls.join(' ')
    let extraBelowMain = hasExtra && hasMainImage
    return (
      <Container className="main-content">
        {hasMainImage ? (
          <section className="main-area two-thirds">
            {hasSubtitle && <h2 className="subtitle">{subtitle}</h2>}
            {renderHTML(mainImage)}
          </section>
        ) : (
            <HeadingOne title={renderHTML(title)} aside={true} />
          )}
        {hasMainImage ? (
          <aside className={textContentClass}>
            <HeadingOne title={title} aside={false} />
            {hasDate && (
              <time className="date">{moment(date).format('D MMM YYYY')}</time>
            )}
            <article className="body">{renderHTML(text)}</article>
          </aside>
        ) : (
            <article className={textContentClass}>
              {hasSubtitle && <h2 className="title">{subtitle}</h2>}
              {hasDate && (
                <time className="date">{moment(date).format('D MMM YYYY')}</time>
              )}
              <div className="body">
                {renderHTML(text)}
                {hasExtra && renderHTML(extra)}
              </div>
            </article>
          )}
        {extraBelowMain && (
          <section className="extra-area two-thirds">
            {renderHTML(extra)}
          </section>
        )}
      </Container>
    )
  }
}

Page.propTypes = {
  pages: PropTypes.array,
  slug: PropTypes.string
}

Page.defaultProps = {
  pages: [],
  slug: ''
}

export default Page

import React from 'react'
import { Container, Segment, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {
  postImage,
  fetchUser,
  fetchTags,
  updateImage,
  deleteImage,
  editUser
} from '../../modules/api'
import config from '../../config/config'
import { fromLocal, toLocal } from '../../modules/localstore'
import Photo from '../elements/photo'
import { WithContext as ReactTags } from 'react-tag-input'
import renderHTML from 'react-render-html'
import { applyPageContent } from '../../modules/cms'

class Contribute extends React.Component {
  mounted = false

  state = {
    title: 'Contribute',
    text: '',
    widgetLoaded: false,
    widget: null,
    loadAttempts: 0,
    images: [],
    loading: false,
    loaded: false,
    numImages: 0,
    hasSelection: false,
    currId: '',
    caption: '',
    editable: false,
    description: '',
    selectedTags: [],
    tags: [],
    userId: '',
    agreed: false,
    showMessage: false,
    message: '',
    errMsg: '',
    textExpanded: false
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.location.pathname !== nextProps.location.pathname ||
      nextState.loaded
    )
  }

  componentWillMount() {
    if (!this.mounted) {
      if (!this.state.loading && !this.state.loaded) {
        this.matchUser()
        this.initCloudinary()
        this.updateImages()
        this.fetchRelatedData()
        this.mounted = true
        this.setState({ loading: true })
        this.loadContent()
      }
    }
    setTimeout(() => {
      this.setState({ loaded: true })
    }, 250)
  }

  loadContent = () => {
    const slug = 'contribute'
    const { pages } = this.props
    applyPageContent(slug, pages).then(vars => {
      this.setState(vars)
    })
  }

  fetchRelatedData = async () => {
    let tagData = await fetchTags()
    if (tagData.items) {
      let suggestions = tagData.items.map((t, ti) => {
        return { id: t._id, text: t.name }
      })
      this.setState({
        tags: suggestions
      })
    }
  }

  initCloudinary = async () => {
    let body = document.body
    let scriptId = 'cloudinary-upload-script'
    let st = document.getElementById(scriptId)

    let ts = 300
    if (!st) {
      st = document.createElement('script')
      st.id = scriptId
      st.src = 'https://widget.cloudinary.com/v2.0/global/all.js'
      st.type = 'text/javascript'
      body.appendChild(st)
      ts = 1200
    }
    setTimeout(() => this.loadWidget(), ts)
  }

  matchUser = async () => {
    let user = await fetchUser()
    if (user) {
      this.setState({
        userId: user._id
      })
      if (user.agreed) {
        this.setState({
          userId: user._id,
          agreed: true
        })
      }
    }
  }

  updateImages = async widget => {
    let stored = fromLocal('user-images', 86400)
    let currImgs = []
    if (stored.valid) {
      if (stored.data instanceof Array) {
        currImgs = stored.data
      }
    }
    let imgs = currImgs
      .map(img => {
        img.className = 'inactive'
        if (/^untitled\.\./i.test(img.caption)) {
          img.caption = ''
        }
        img.approved = false
        switch (img.status) {
          case 'approved':
            img.approved = true
            break
          case 'uploaded':
          case 'authorised':
          case 'rejected':
            img.mayDelete = true
            break
          default:
            img.mayDelete = false
            break
        }
        img.needsCaption = img.caption.length < 3
        return img
      })
      .sort((a, b) => b.created - a.created)
    this.setState({
      images: imgs,
      numImages: stored.data.length
    })

    if (widget) {
      setTimeout(() => {
        widget.close()
        this.setState({
          message: config.labels.thankyouUploading,
          showMessage: true
        })
        if (this.state.images.length > 0) {
          this.selectImage({
            _id: this.state.images[0]._id,
            className: 'inactive'
          })
        }
        setTimeout(() => {
          this.hideMessage()
        }, 30 * 1000)
      }, 500)
    }
  }

  hideMessage = () => {
    this.setState({
      showMessage: false
    })
  }

  updateImageData = e => {
    e.stopPropagation()
    let { currId, selectedTags, caption, description, placename } = this.state
    let valid = false
    let errMsg = ''
    if (currId.length > 3) {
      caption = caption.trim()
      description = description.trim()
      let data = {
        caption: caption,
        description: description,
        tags: selectedTags.map(t => t.text)
      }
      if (placename) {
        data.location = {
          placename: placename
        }
      }
      valid = caption.length > 2
      if (valid) {
        updateImage(currId, data, response => {
          if (response.success) {
            this.updateImages()
            this.selectImage({
              _id: currId,
              className: 'active'
            })
          }
        })
      } else {
        errMsg = 'Please add a caption'
      }
    }
    this.setState({ errMsg: errMsg })
  }

  deleteImage = async (img, e) => {
    e.stopPropagation()
    if (img.user) {
      let userId = ''
      if (typeof img.user === 'string') {
        userId = img.user
      } else if (img.user._id) {
        userId = img.user._id
      }
      if (userId === this.state.userId) {
        deleteImage(img._id).then(res => {
          if (res.valid) {
            this.setState({
              message: `Image (${img.caption}) removed`,
              showMessage: true
            })
            setTimeout(() => this.removeImage(img), 1000)
          }
        })
      }
    }
  }

  removeImage = img => {
    let imgs = this.state.images.filter(im => im._id !== img._id)
    this.setState({
      images: imgs,
      numImages: imgs.length,
      hasSelection: false,
      showMessage: false
    })
    toLocal('user-images', imgs)
  }

  selectTag = tag => {
    let tags = this.state.selectedTags
    let newTag = null
    if (typeof tag == 'string') {
      tag = tag.trim()
      if (tag.length > 2) {
        newTag = {
          id: tag,
          text: tag
        }
      }
    } else if (tag !== null) {
      newTag = tag
    }
    if (newTag) {
      tags.push(newTag)
    }
    this.setState({ selectedTags: tags })
  }

  handleDrag = (tag, currPos, newPos) => {
    let tags = this.state.selectedTags
    tags.splice(currPos, 1)
    tags.splice(newPos, 0, tag)
    this.setState({ selectedTags: tags })
  }

  deleteTag = async tagIndex => {
    if (typeof tagIndex === 'number' && tagIndex >= 0) {
      let selTags = this.state.selectedTags
      if (tagIndex < selTags.length) {
        selTags.splice(tagIndex, 1)
        this.setState({ selectedTags: selTags })
      }
    }
  }

  selectImage = async image => {
    let newState = image.className === 'active' ? 'inactive' : 'active'
    let imgs = this.state.images.map(img => {
      img.className = image._id === img._id ? newState : 'inactive'
      return img
    })
    let selTags = []
    let currId = ''
    let hasSelection = false
    let caption = ''
    let description = ''
    let placename = ''
    if (newState === 'active') {
      if (image.tags) {
        selTags = image.tags.map((t, ti) => {
          return {
            id: t._id,
            text: t.name
          }
        })
      }
      if (image.caption) {
        caption = image.caption
      }
      if (image.description) {
        description = image.description
      }
      if (image.hasPlacename) {
        placename = image.location.placename
      }
      currId = image._id
      hasSelection = true
    }
    let editable = image.status !== 'approved'
    this.setState({
      images: imgs,
      currId: currId,
      selectedTags: selTags,
      caption: caption,
      description: description,
      placename: placename,
      hasSelection: hasSelection,
      editable: editable
    })
  }

  updateValue = e => {
    if (e.target.name) {
      let nm = e.target.name
      let params = {}
      params[nm] = e.target.value
      this.setState(params)
    }
  }

  agreeToTerms = e => {
    if (e.target.name) {
      if (e.target.checked) {
        const params = {
          agreed: true
        }
        editUser(this.state.userId, params).then(u => {
          this.setState({
            agreed: true
          })
          this.loadWidget()
        })
      }
    }
  }

  loadWidget = async () => {
    let la = this.state.loadAttempts
    let widget = null
    if (window.cloudinary) {
      widget = document.getElementById('upload-widget')
      la++
      this.setState({ widget: widget, loadAttempts: la })
    } else if (la < 5) {
      setTimeout(() => {
        this.loadWidget()
      }, 2000)
    }
  }

  removeCloudinaryIframe = () => {
    let iframes = document.querySelectorAll('iframe')
    let loaded = false
    let ifr = null
    if (iframes.length) {
      for (let i = 0; i < iframes.length; i++) {
        ifr = iframes[i]
        if (ifr.src) {
          loaded = ifr.src.includes('cloudinary')
          if (loaded) {
            ifr.parentNode.removeChild(ifr)
            break
          }
        }
      }
    }
  }

  openWidget = async () => {
    if (this.state.widget) {
      this.removeCloudinaryIframe()
      let user = await fetchUser()
      let widget = window.cloudinary.openUploadWidget(
        config.cloudinary.widget,
        (error, result) => {
          if (!error) {
            switch (result.event) {
              case 'success':
                if (result.info) {
                  result.info.caption = 'untitled.....'
                  result.info.user = user._id
                  postImage(result.info, 'upload', data => {
                    if (data.success) {
                      this.updateImages(widget)
                    }
                  })
                }
                break
            }
          }
        }
      )
    }
  }

  toggleTextExpanded = () => {
    this.setState({
      textExpanded: this.state.textExpanded !== true
    })
  }

  render() {
    let {
      title,
      subtitle,
      text,
      images,
      numImages,
      hasSelection,
      selectedTags,
      tags,
      caption,
      description,
      placename,
      editable,
      agreed,
      showMessage,
      message,
      errMsg,
      textExpanded
    } = this.state
    let cls = ['main-area', 'user-images', 'two-thirds']
    if (hasSelection) {
      cls.push('show-selection')
    }
    if (showMessage) {
      cls.push('show-message')
    }
    let mainClassNames = cls.join(' ')
    let hasSubtitle = typeof subtitle === 'string' ? subtitle.length > 1 : false
    cls = ['edit-panel']
    if (!editable) {
      cls.push('disabled')
    }
    let formClassNames = cls.join(' ')

    cls = ['details-wrapper']
    let textContractible = agreed && numImages > 0
    if (!textContractible) {
      textExpanded = true
    }
    if (textContractible && !textExpanded) {
      cls.push('text-contracted')
    }
    let textPaneClasses = cls.join(' ')
    let toggleTextExpandedLabel = textExpanded
      ? 'Hide instructions'
      : 'Show instructions'
    return (
      <Container className="main-content">
        <aside className="side-panel">
          <h1 className="title">{title}</h1>
          {hasSubtitle && <h2 className="subtitle">{subtitle}</h2>}
          <article className={textPaneClasses}>
            {hasSelection ? (
              <div className={formClassNames}>
                <h4>Edit photo details</h4>
                <ReactTags
                  tags={selectedTags}
                  suggestions={tags}
                  className="textfield textfield-long"
                  handleDelete={this.deleteTag}
                  handleAddition={this.selectTag}
                  handleDrag={this.handleDrag}
                  handleInputBlur={this.selectTag}
                  inline
                />
                <input
                  name="caption"
                  type="text"
                  value={caption}
                  minLength={2}
                  size={64}
                  maxLength={64}
                  placeholder="Brief caption"
                  required
                  className="textfield textfield-long"
                  onChange={this.updateValue}
                  title="Maximum of 64 characters"
                  disabled={!editable}
                />
                <textarea
                  name="description"
                  type="text"
                  rows={2}
                  cols={64}
                  maxLength={200}
                  placeholder="Description"
                  className="textfield textarea"
                  value={description}
                  onChange={this.updateValue}
                  title="Maximum pf 200 characters"
                  disabled={!editable}
                />
                <input
                  name="placename"
                  type="text"
                  value={placename}
                  minLength={2}
                  size={64}
                  placeholder="Location"
                  required
                  className="textfield textfield-long"
                  onChange={this.updateValue}
                  disabled={!editable}
                />
                <div className="actions">
                  {errMsg.length > 2 && <p className="error">{errMsg}</p>}
                  {editable && (
                    <Icon className="save" onClick={this.updateImageData}>
                      <span className="label">Save</span>
                    </Icon>
                  )}
                </div>
              </div>
            ) : (
                <div className="uploader">
                  {agreed && (
                    <button
                      id="upload-widget"
                      className="cloudinary-button"
                      onClick={() => this.openWidget()}>
                      Upload files
                    <Icon className="cloud upload" />
                    </button>
                  )}
                  {!agreed && (
                    <div className="agree-to-terms-row">
                      <p>
                        Please confirm you have read the{' '}
                        <Link to="/info/terms-and-conditions">
                          Terms and Conditions
                      </Link>{' '}
                        before submiting photographs to this site.
                    </p>
                      <input
                        id="agree-to-terms"
                        name="agreed"
                        type="checkbox"
                        className="checkbox"
                        onChange={this.agreeToTerms}
                      />
                      <label htmlFor="agree-to-terms">
                        I Agree to terms and conditions
                    </label>
                    </div>
                  )}

                  <div className="text">{renderHTML(text)}</div>
                  <div
                    className="expand-text mobile-only"
                    onClick={this.toggleTextExpanded}>
                    {toggleTextExpandedLabel}
                  </div>
                </div>
              )}
          </article>
        </aside>
        <section className={mainClassNames}>
          <div className="message-pane">
            <div className="text">{message}</div>
            <i className="icon close" onClick={this.hideMessage} />
          </div>
          <Segment.Group id="user-images" horizontal>
            {numImages > 0 ? (
              images.map((img, index) => (
                <Segment
                  as="figure"
                  key={index}
                  className={`${img.className} ${img.status}`}
                  onClick={() => this.selectImage(img)}>
                  <Photo
                    img={img}
                    width={1200}
                    height={1200}
                    className="preview"
                  />
                  {img.approved && <Icon className="check" title="Approved" />}
                  <div className="status">
                    <span className="text">{img.status}</span>
                    {img.mayDelete && (
                      <Icon
                        className="trash"
                        title="Delete"
                        onClick={e => this.deleteImage(img, e)}
                      />
                    )}
                  </div>
                  {!img.approved && img.needsCaption && (
                    <Icon
                      className="warning sign standalone"
                      title="Please add a caption"
                    />
                  )}
                  <Icon className="close" />
                  <figcaption>
                    {img.needsCaption ? (
                      <p className="needs-caption">
                        <Icon className="warning sign" />
                        Please add a caption
                      </p>
                    ) : (
                        <p className="caption">{img.caption}</p>
                      )}

                    {img.tags && (
                      <ul className="tags">
                        {img.tags.map((t, ti) => (
                          <li key={ti}>{t.name}</li>
                        ))}
                      </ul>
                    )}
                    <p className="description">{img.description}</p>
                    {img.hasPlacename && (
                      <p className="placename">{img.location.placename}</p>
                    )}
                    <Icon className="edit" />
                  </figcaption>
                </Segment>
              ))
            ) : (
                <Segment
                  as="figure"
                  className="large-placeholder"
                  onClick={() => this.openWidget()}>
                  <Icon className="photo" />
                  <figcaption>
                    <p>No images uploaded yet.</p>
                  </figcaption>
                </Segment>
              )}
          </Segment.Group>
        </section>
      </Container>
    )
  }
}

export default Contribute

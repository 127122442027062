import React, { Component } from 'react'
import config from '../../config/config'
import {transformCloudinary, transformLocal} from '../../modules/utils'

class Picture extends Component {
  urlTransform = (img, size, density) => {
    if (density) {
      density = ' ' + density
    } else {
      density = ''
    }
    const {cloudinary} = config
    let uri = ""
    switch (img.source) {
      case 'local':
        uri = transformLocal(img, size)
        break
      default:
        size.crop = "fit"
        size.quality = cloudinary.quality
        uri = transformCloudinary(img, cloudinary, size)
        break
    }
    return `${uri}${density}`
  }

  mediaTransform = size => {
    let comps = []
    if (size.min) {
      comps.push(`(min-width: ${size.min}px)`)
    }
    if (size.max) {
      comps.push(`(max-width: ${size.max}px)`)
    }
    return comps.join(' and ')
  }

  setHover = over => {
    if (this.props.setHover) {
      if (over !== false) {
        over = true
      }
      this.props.setHover(over)
    }
  }

  unsetHover = () => {
    this.setHover(false)
  }

  render() {
    const { sizes, img } = this.props
    const defaultSize = sizes.find(size => size.default)
    const src = this.urlTransform(img, defaultSize)
    return (
      <picture className="image-set">
          {sizes.map((size, index) => (
            <source
              srcSet={this.urlTransform(img, size, '1x')}
              media={this.mediaTransform(size)}
              key={index}
            />
          ))}
        <img
          src={src}
          alt={img.caption}
          onMouseOver={this.setHover}
          onMouseOut={this.unsetHover}
        />{' '}
      </picture>
    )
  }
}

export default Picture

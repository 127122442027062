import React from 'react'
import { Container, Segment } from 'semantic-ui-react'
import Post from './post'
import {fetchPosts} from '../../modules/api'

class News extends React.Component {
  state = {
    title: 'News',
    posts: [],
    numPosts: 0
  }

  async componentDidMount() {
    this.assignPageVars()
  }

  assignPageVars = async () => {
    let posts = [];
    let numPosts = 0;
    await fetchPosts()
      .then(data => {
        if (data.valid) {
          posts = data.items
          numPosts = data.items.length
        }
      });
    this.setState({
      posts,
      numPosts
    })
  }

  render() {
    const {
      title,
      posts,
      numPosts
    } = this.state
    return (
      <Container className="main-content">
        <h1>{title}</h1>
        <Segment.Group className="news-items">
          {numPosts > 0 && posts.map((post, index) => (
            <Post posts={posts} slug={post.slug} key={index} index={index} />
          ))}
        </Segment.Group>
        <div className="clear"></div>
      </Container>
    )
  }
}

export default News

import React from 'react'
import { Container } from 'semantic-ui-react'
import { WithContext as ReactTags } from 'react-tag-input'
import {
  fetchImages,
  fetchImage,
  fetchTags,
  fetchArtists
} from '../../modules/api'
import MainImageSet from '../elements/main-image-set'
import Grid from '../elements/grid'
import config from '../../config/config'
import { fromLocal } from '../../modules/localstore'

class Gallery extends React.Component {
  timeout = null

  interval = null

  scrollTs = 0

  prevScrollY = 0

  state = {
    title: 'Gallery',
    images: [],
    numImages: 0,
    loadOffset: 0,
    loading: false,
    total: 0,
    tags: [],
    numTags: 0,
    artists: [],
    numArtists: 0,
    activeFilter: 'all',
    filterName: 'All',
    restart: false,
    mainIndex: -1,
    selectedTags: [],
    lastUpdated: null,
    refresh: false
  }

  componentWillMount = async () => {
    this.loadImages()
    let tagData = await fetchTags()
    this.setState({
      tags: tagData.items,
      numTags: tagData.num
    })
    let aData = await fetchArtists()
    this.setState({
      artists: aData.items,
      numArtists: aData.num
    })

    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('scroll', this.handleScroll)
  }

  showIdImage = async () => {
    let idStr = this.props.location.pathname.split('/').pop()
    if (idStr !== 'gallery') {
      let tgImg = this.state.images.find(img => img._id.toString() === idStr)
      if (tgImg) {
        this.setMain(tgImg, false)
      } else {
        tgImg = await fetchImage(idStr)
        if (tgImg) {
          if (tgImg.valid) {
            this.setMain(tgImg, false, true)
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('scroll', this.handleScroll)
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (this.interval) {
      clearTimeout(this.interval)
    }
  }

  handleScroll = e => {
    if (window.scrollY) {
      if (window.innerHeight) {
        let sY = window.scrollY
        let frac = sY / window.innerHeight
        if (sY > this.prevScrollY + 32 && (frac > 0.25 || sY > 200)) {
          this.prevScrollY = sY
          let tgPane = document.querySelector('#masonry-grid')
          if (tgPane) {
            let loadMoreHeight = window.scrollY + window.innerHeight * 3;
            if (tgPane.clientHeight < loadMoreHeight) {
              this.loadMore()
            }
          }
        } else if (this.state.total > this.state.numImages) {
          if (this.scrollTs < 1 || e.timeStamp > this.scrollTs + 500) {
            this.scrollTs = e.timeStamp
            this.prevScrollY = 200
          }
        }
      }
    }
    return true
  }

  handleTimeout = e => {
    this.loadMore()
  }

  loadMore = () => {
    if (this.state.total > this.state.numImages) {
      if (!this.state.loading) {
        this.loadImages()
      }
    } else {
      window.removeEventListener('scroll', this.handleScroll)
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  loadImages = async (restart, update) => {
    let { loadOffset, total } = this.state
    if (restart === true) {
      loadOffset = 0
    }
    let refresh = false
    let stored = fromLocal('last-updated', 60 * 60)
    if (stored.valid) {
      if (stored.data.refresh) {
        refresh = true
      }
    }

    this.setState({ loading: true })
    let perLoad = config.gallery.perLoad
    if (update) {
      loadOffset = 0
    }
    let data = await fetchImages(
      this.state.activeFilter,
      loadOffset,
      perLoad,
      refresh
    )
    if (data.total) {
      total = data.total
    }
    if (data.items instanceof Array) {
      data.items = data.items
      let numNew = data.items.length
      let images = []
      if (update) {
        let newImages = []
        images = this.state.images.map(im => {
          let img = data.items.find(im2 => im2._id === im._id)
          if (img) {
            return img
          } else {
            return im
          }
        })
        images = [...newImages, images]
      } else {
        images =
          restart === true ? data.items : [...this.state.images, ...data.items]
      }

      if (numNew < 1) {
        total = images.length
      }
      this.setState({
        images: images,
        numImages: images.length,
        total: total,
        loadOffset: loadOffset + perLoad,
        restart: restart === true
      })
      if (this.state.mainIndex < 0 && loadOffset < 1) {
        setTimeout(() => {
          this.showIdImage()
        }, 500)
      }
      if (data.lastUpdated) {
        this.setState({
          lastUpdated: data.lastUpdated
        })
      }
    }
    if (restart === true) {
      setTimeout(() => {
        this.setState({ restart: false, refresh: false })
      }, 10)
    }

    setTimeout(() => {
      this.setState({ loading: false })
    }, 500)
  }

  setMain = async (targetImg, updateUri, skipIndex) => {
    let index = -1
    if (skipIndex !== true) {
      index = this.state.images.findIndex(im => im._id === targetImg._id)
    } else {
      let imgs = this.state.images
      imgs.push(targetImg)
      this.setState({
        images: imgs,
        numImages: imgs.length
      })
      index = imgs.length - 1
    }
    if (index >= 0) {
      this.setState({
        mainIndex: index
      })
      if (updateUri !== false) {
        this.props.history.push('/gallery/' + targetImg._id)
      }
    }
  }

  unsetMain = () => {
    let { mainIndex } = this.state
    if (mainIndex >= 0) {
      this.setState({
        mainIndex: -1
      })
      this.props.history.push('/gallery')
    }
  }

  filterByTag(tag) {
    if (tag.slug !== this.state.activeFilter) {
      this.setState({
        activeFilter: tag.slug,
        filterName: tag.name
      })
      setTimeout(() => this.loadImages(true), 100)
    }
  }

  filterByUser(user) {
    let ref = 'u--' + user.id
    if (ref !== this.state.activeFilter) {
      this.setState({
        activeFilter: ref,
        filterName: user.name
      })
      setTimeout(() => this.loadImages(true), 100)
    }
  }

  selectTag = tag => {
    if (tag !== null && typeof tag == 'object') {
      let matched = false
      switch (tag.type) {
        case 'tag':
          this.filterByTag(tag)
          matched = true
          break
        case 'artist':
          this.filterByUser(tag)
          matched = true
          break
      }
      if (matched) {
        this.setState({
          selectedTags: [tag]
        })
      }
    }
  }

  deleteTag = e => {
    let valid = true
    if (e) {
      if (e.target) {
        valid = false
        if (e.target.classList.contains('ReactTags__tag')) {
          valid = true
        }
      }
    }
    if (valid) {
      this.filterByTag('all')
      this.setState({
        selectedTags: []
      })
    }
  }

  handleSuggestions = (txt, suggestions) => {
    let lcTxt = txt.toLowerCase()
    return suggestions.filter(suggestion => {
      return suggestion.name.toLowerCase().includes(lcTxt)
    })
  }

  combineTags = () => {
    let { artists, tags } = this.state
    return artists.concat(tags).map(item => {
      let txt = ''
      let name = ''
      let sType = ''
      if (item.displayName) {
        sType = 'artist'
        txt = item.displayName
        name = item.displayName
      } else if (item.name) {
        sType = 'tag'
        txt = item.name
        name = item.name
      }
      if (sType) {
        txt += ' [' + sType + ']'
      }
      txt += ' (' + item.numImages + ')'
      let slug = item.slug ? item.slug : item._id

      return {
        id: item._id,
        text: txt,
        slug: slug,
        name: name,
        type: sType
      }
    })
  }

  render() {
    let {
      title,
      images,
      numImages,
      numArtists,
      total,
      filterName,
      restart,
      mainIndex,
      selectedTags
    } = this.state
    const mixedTags = this.combineTags()
    let cls = ['top-bar']
    if (selectedTags.length > 0) {
      cls.push('is-filtered')
    }
    if (!filterName) {
      filterName = 'All'
    }
    let topClassNames = cls.join(' ')
    return (
      <Container className={mainIndex >= 0 ? 'show-main' : 'thumbnails'}>
        <h1 className="main">
          <span className="title" onClick={this.clearCaches}>
            {title}
          </span>
        </h1>
        <div className={topClassNames}>
          <div className="info">
            <span className="filter small" onClick={e => this.deleteTag(e)}>
              {filterName}
            </span>
            <span className="num-images label small">Pictures</span>
            <em
              className="num-images"
              title={`loaded ${numImages} of ${total}`}>
              {total}
            </em>

            <span className="artists label small">Artists</span>
            <em className="num-artists">{numArtists}</em>
          </div>
          <ReactTags
            tags={selectedTags}
            suggestions={mixedTags}
            className="textfield textfield-long"
            handleAddition={this.selectTag}
            handleDelete={this.deleteTag}
            allowDragDrop={false}
            placeholder="Search"
            handleFilterSuggestions={this.handleSuggestions}
            minQueryLength={1}
            inline
          />
        </div>
        <Grid
          images={images}
          restart={restart === true}
          setMain={this.setMain.bind(this)}
        />
        <MainImageSet
          images={images}
          initMainIndex={mainIndex}
          unsetMain={this.unsetMain}
          setMain={this.setMain}
          loadMore={this.loadMore}
        />
      </Container>
    )
  }
}

export default Gallery

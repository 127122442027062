import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Icon } from 'semantic-ui-react'
import { animateScroll } from 'react-scroll'
import PropTypes from 'prop-types'
import Gallery from '../gallery/index'
import Page from '../page/index'
import News from '../news/index'
import Contribute from '../contribute/index'
import CurationsList from '../curations/index'
import CurationGallery from '../curations/gallery'
import UserWidget from '../user/widget'
import MenuList from '../elements/menu-list'
import Footer from '../elements/footer'
import Login from '../user/login'
import {
  fetchUser,
  fetchPages,
  checkVersion,
  lastUpdated
} from '../../modules/api'
import config from '../../config/config'
import { clearLocal } from '../../modules/localstore'
import { addBodyClass } from '../../modules/utils'

class App extends React.Component {
  version = 24

  section = 'home'

  subsection = ''

  state = {
    loggedIn: false,
    title: 'Portrait of an Egg',
    wrapperClass: 'wrapper',
    footerData: {},
    hasFooter: false,
    pages: [],
    showMenu: false,
    showLogin: true,
    updateCheckCount: 0
  }

  static contextTypes = {
    router: PropTypes.object
  }

  componentWillMount = async () => {
    checkVersion(this.version)
    if (config.footerData.logos instanceof Array) {
      config.footerData.logos = config.footerData.logos.map(img => {
        img.className = img.uri
          .split('/')
          .pop()
          .split('.')
          .shift()
        return img
      })
    }
    this.setState({
      footerData: config.footerData,
      hasFooter: true
    })
    let matchedUser = await fetchUser()
    if (matchedUser.identifier) {
      this.setState({
        loggedIn: true
      })
    }
    let pageData = await fetchPages()
    if (pageData.valid) {
      this.setState({
        pages: pageData.items
      })
    }
    this.props.history.listen((location, action) => {
      this.setState({ showMenu: false })
    })
    setTimeout(this.lastUpdated, 30 * 1000)
    setInterval(this.lastUpdated, 600 * 1000)
  }

  componentDidMount() {
    this.handleLocationChange(this.context.router.history.location)
    this.unlisten = this.context.router.history.listen(
      this.handleLocationChange
    )
  }

  componentWillUnmount() {
    this.unlisten()
  }

  handleLocationChange = location => {
    let section = 'home'
    let sub1 = ''
    let toTop = true
    if (location.pathname.length > 2) {
      let locParts = location.pathname.replace(/^\//, '').split('/')
      section = locParts.shift()
      if (locParts.length > 0) {
        sub1 = locParts.shift()
      }
    }
    switch (section) {
      case 'gallery':
        if (this.section === section || sub1.length > 2) {
          toTop = false
        }
        break
      case 'hall-of-fame':
        if (this.section === section && sub1 === this.subsection) {
          toTop = false
        }
        break
    }
    if (toTop) {
      let sTop = window.scrollY / window.innerHeight
      let dur = Math.pow(sTop, 0.5) * window.innerHeight
      if (dur < 250) {
        dur = 250
      } else if (dur > 1500) {
        dur = 1500
      }
      animateScroll.scrollToTop({
        duration: dur,
        smooth: true
      })
      this.section = section
      this.subsection = sub1
    }
  }

  render() {
    return this.props.children
  }

  toggleMenu = () => {
    let sm = this.state.showMenu !== true
    this.setState({
      showMenu: sm
    })
  }

  closeMenu = e => {
    if (e.target) {
      if (this.state.showMenu) {
        let tn = e.target.tagName.toLowerCase()
        switch (tn) {
          case 'li':
          case 'i':
            break
          default:
            this.setState({ showMenu: false })
            break
        }
      }
    }
  }

  registerLogin = (inMode, provider) => {
    if (inMode !== false) {
      inMode = true
    }
    if (this.props.location.search && inMode === true) {
      if (/rslCallback=instagram/i.test(this.props.location.search)) {
        this.props.history.replace(this.props.location.pathname)
      }
    }
    if (!inMode && provider === 'instagram') {
      this.props.history.replace('/gallery')
    }
    let st = {
      loggedIn: inMode
    }
    if (!inMode) {
      st.showLogin = false
      setTimeout(() => {
        this.setState({
          showLogin: true
        })
      }, 1000)
    }
    this.setState(st)
  }

  lastUpdated = () => {
    let { updateCheckCount } = this.state
    if (updateCheckCount < 12) {
      let fetchAll = updateCheckCount < 1
      this.setState({ updateCheckCount: updateCheckCount + 1 })
      lastUpdated(fetchAll).then(d => {
        if (d.refresh) {
          this.clearImageCaches()
        }
      })
    }
  }

  clearImageCaches = () => {
    clearLocal('image-list', true)
  }

  render() {
    let {
      loggedIn,
      title,
      wrapperClass,
      footerData,
      pages,
      showMenu,
      showLogin,
      hasFooter
    } = this.state
    let { location } = this.props
    let current = location.pathname
    let cls = [wrapperClass]
    let pathParts = current.replace(/^\//, '').split('/')
    let pathSlug = pathParts.join('-')
    let numParts = pathParts.length
    let section = pathParts.shift()
    if (section !== pathSlug) {
      cls.push(section)
    }
    cls.push(pathSlug)
    let darkMode = false
    switch (section) {
      case 'gallery':
      case 'hall-of-fame':
        cls.push('full-width')
        cls.push('black-bg')
        darkMode = true
        break
      case 'news':
        if (numParts > 1) {
          cls.push('right-col-offset')
        } else {
          cls.push('full-width')
        }
        break
      default:
        cls.push('right-col-offset')
        break
    }
    addBodyClass('dark-mode', darkMode)
    if (loggedIn) {
      cls.push('logged-in')
    }
    let wrapperClassNames = cls.join(' ')
    let cls2 = ['inner-wrapper']
    if (showMenu) {
      cls2.push('show-menu')
    }
    let headerClassNames = cls2.join(' ')
    return (
      <div className={wrapperClassNames}>
        <header className={headerClassNames} onClick={this.closeMenu}>
          <figure className="logo" onClick={this.clearImageCaches}>
            <figcaption>{title}</figcaption>
          </figure>
          <nav className="main-nav">
            <Icon className="bars" onClick={this.toggleMenu} />
            <MenuList
              items={config.mainMenuItems}
              current={current}
              className="main-menu"
              loggedIn={loggedIn}
            />
            {loggedIn && (
              <UserWidget registerLogin={this.registerLogin.bind(this)} />
            )}
          </nav>
        </header>

        <main className="inner-wrapper">
          <Switch loggedIn={loggedIn}>
            <Route
              exact
              path="/"
              component={() => <Page slug="project" pages={pages} />}
            />
            <Route exact path="/gallery/:id?" component={Gallery} />
            <Route
              exact
              path="/about"
              component={() => <Page slug="about" pages={pages} />}
            />
            <Route exact path="/news" component={News} />
            <Route
              exact
              path="/hall-of-fame"
              component={() => (
                <CurationsList slug="hall-of-fame" pages={pages} />
              )}
            />
            <Route
              exact
              path="/hall-of-fame/:title/:id?"
              component={CurationGallery}
            />
            <Route exact path="/news/:date/:title" component={Page} />
            {loggedIn ? (
              <Route
                exact
                path="/contribute"
                component={({ ...props }) => (
                  <Contribute pages={pages} {...props} />
                )}
              />
            ) : (
                <Route
                  exact
                  path="/contribute"
                  component={() => (
                    <Login
                      registerLogin={this.registerLogin.bind(this)}
                      showLogin={showLogin}
                      pages={pages}
                    />
                  )}
                />
              )}
            <Route
              exact
              path="/info/:title"
              component={() => <Page pages={pages} slug="--info" />}
            />
          </Switch>
        </main>
        {hasFooter && <Footer data={footerData} current={current} />}
      </div>
    )
  }
}

export default withRouter(App)

import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton
} from 'react-share';
import config from '../../config/config'

class SocialShare extends Component {
  
  render() {
    let {title, description, path, tags, media} = this.props
    let text = config.title + ':' + title + "\n" + description
    let hashtags = ['gavinturkegg'];
    if (tags instanceof Array) {
      hashtags = [...hashtags, ...tags.map(tg => tg.name.toLowerCase())]
    }
    let hashtag = hashtags[0]
    let url = window.location.origin + path
    return (
      <div className="social-share-buttons">
        <TwitterShareButton title={text} url={url} hashtags={hashtags}><Icon className="twitter" /></TwitterShareButton>
        <FacebookShareButton url={url}><Icon className="facebook" /></FacebookShareButton>
        <PinterestShareButton description={text} url={url} media={media}><Icon className="pinterest" /></PinterestShareButton>
      </div>
    )
  }
}

export default SocialShare

import React from 'react'
import { Container } from 'semantic-ui-react'
import { fetchCuration } from '../../modules/api'
import PictureList from '../elements/picture-list'
import MainImageSet from '../elements/main-image-set'
import Photo from '../elements/photo'

class CurationGallery extends React.Component {
  mounted = false

  state = {
    slug: '',
    title: 'Hall of Fame',
    text: '',
    bio: '',
    user: {
      displayName: null
    },
    mainImage: {},
    hasProfileUri: false,
    images: [],
    mainIndex: -1,
    num: 0
  }

  componentWillMount() {
    if (this.props.location.pathname) {
      let parts = this.props.location.pathname.split('/')
      let mainImageId = ''
      if (parts.length > 1) {
        let slug = parts.pop()
        if (/^[0-9a-f]+$/i.test(slug) && parts.length > 1) {
          mainImageId = slug
          slug = parts.pop()
        }
        let section = parts.pop()
        switch (section) {
          case 'curation':
          case 'curations':
          case 'hall-of-fame':
            this.setState({
              slug: slug
            })
            fetchCuration(slug).then(d => {
              let sd = {}
              sd.title = d.title
              if (d.valid && d.images.length > 0) {
                sd.images = d.images.map((img, index) => {
                  img.index = index
                  return img
                })
                sd.num = d.num
              }
              if (d.hasProfileUri) {
                sd.mainImage = d.mainImage
                sd.hasProfileUri = d.hasProfileUri
              }
              if (d.user) {
                sd.user = d.user
              }
              sd.text = d.text
              if (d.bio) {
                sd.bio = d.bio
              }
              this.setState(sd)
              if (mainImageId.length > 5) {
                let tgImg = sd.images.find(im => im._id === mainImageId)
                if (tgImg) {
                  this.setMain(tgImg)
                }
              }
            })
            break
        }
      }
    }
  }

  setMain = async targetImg => {
    let { images, slug } = this.state
    let index = images.findIndex(im => im._id === targetImg._id)
    if (index >= 0) {
      this.setState({
        mainIndex: index
      })
      this.props.history.push('/hall-of-fame/' + slug + '/' + targetImg._id)
    }
  }

  unsetMain = () => {
    let { slug, mainIndex } = this.state
    if (mainIndex >= 0) {
      this.setState({
        mainIndex: -1
      })
      this.props.history.push('/hall-of-fame/' + slug)
    }
  }

  render() {
    let {
      title,
      text,
      bio,
      user,
      mainImage,
      images,
      mainIndex,
      hasProfileUri
    } = this.state
    let cls = ['main-content']
    if (mainIndex >= 0) {
      cls.push('show-main')
    }
    let wrapperClasses = cls.join(' ')
    return (
      <Container className={wrapperClasses}>
        <h1 className="title" title={user.displayName}>
          {title}
        </h1>
        <div className="inner-content top-content">
          {hasProfileUri && (
            <Photo
              img={mainImage}
              height={200}
              width={200}
              crop="fit"
              className="prodile-image left"
              title={bio}
            />
          )}
          <div className="text-content">{text}</div>
        </div>
        <PictureList images={images} setMain={this.setMain.bind(this)} />
        <MainImageSet
          images={images}
          initMainIndex={mainIndex}
          unsetMain={this.unsetMain}
          setMain={this.setMain}
        />
      </Container>
    )
  }
}

export default CurationGallery

import React from 'react'
import renderHTML from 'react-render-html'
import moment from 'moment'
import {assignPageVars} from '../../modules/cms'
import { Link } from 'react-router-dom'

const Excerpt = ({html, fullLink}) => {
  return (<p className="excerpt">{renderHTML(html)} <Link to={fullLink} className="more">more</Link></p>)
}

class Post extends React.Component {
  state = {
    title: 'Page title',
    text: '',
    hasMainImage: false,
    date: '',
    mainImage: null,
    excerpt: ''
  }

  async componentDidMount() {
    const { posts, slug } = this.props
    const post = posts.find(p => p.slug === slug)
    if (post) {
      if (post.title) {
        if (post.content instanceof Array) {
          assignPageVars(post)
            .then(vars => {
              this.setState(vars);
            })
        }
      }
    }
  }

  render() {
    const {
      title,
      excerpt,
      hasMainImage,
      mainImage,
      date
    } = this.state
    const { slug, index } = this.props
    let fullLink = '/news/' + moment(date).format('YYYY-MM') + '/' + slug
    let cls = ['post']
    if (index % 2 === 0) {
      cls.push('even')
    } else {
      cls.push('odd')
    }
    let classNames = cls.join(' ')
    return (
      <article className={classNames}>
        <div className="media">
          {hasMainImage && <Link to={fullLink}>{renderHTML(mainImage)}</Link>}
        </div>
        <div className="text-content">
          <h3 className="title"><Link to={fullLink}>{title}</Link></h3>
          <time className="date">{moment(date).format('D MMM YYYY')}</time>
          <div className="body"><Excerpt html={excerpt} fullLink={fullLink} /></div>
        </div>
        <div className="clear"></div>
      </article>
    )
  }
}

export default Post

import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import Picture from './picture'
import SocialShare from './social-share'
import { likeImage } from '../../modules/api'
import { fromLocal } from '../../modules/localstore'
import config from '../../config/config'

class MainPhoto extends Component {
  state = {
    numLikes: 0,
    hasLiked: false,
    hover: false
  }

  componentDidMount() {
    this.assignLikes()
  }

  componentDidUpdate(prevProps, prevState) {
    let prevId = ''
    if (prevProps.img) {
      if (prevProps.img._id) {
        prevId = prevProps.img._id.toString()
      }
      let { img } = this.props
      let currId = img._id.toString()
      if (prevId !== currId) {
        this.assignLikes()
      }
    }
  }

  assignLikes = () => {
    let { likes } = this.props.img
    let stored = fromLocal('likes', config.likes.timeoutHours * 3600)
    let hasLiked = false
    if (stored.valid) {
      if (stored.data instanceof Array) {
        let item = stored.data.find(
          it => it.imgId.toString() === this.props.img._id.toString()
        )
        if (item) {
          likes = item.num
          hasLiked = true
        }
      }
    }
    this.setState({
      numLikes: likes,
      hasLiked: hasLiked
    })
  }

  like = () => {
    likeImage(this.props.img._id).then(d => {
      if (d.num) {
        this.setState({
          numLikes: d.num,
          hasLiked: true
        })
      }
    })
  }

  setHover = over => {
    this.setState({
      hover: over !== false
    })
  }

  buildDescription = () => {
    const { img } = this.props
    let parts = []
    if (typeof img.description == 'string' && img.description.length > 5) {
      parts.push(img.description)
    }
    if (img.description) {
      parts.push(img.description)
    }
    if (img.user.displayName) {
      parts.push(img.user.displayName)
    }
    return parts.join(' - ')
  }

  render() {
    const { img, sizes } = this.props
    let { numLikes, hasLiked } = this.state
    const hasDescription =
      typeof img.description === 'string' && img.description.length > 2
    let hasUser = false
    if (img.user) {
      if (img.user.displayName) {
        hasUser = true
      }
    }
    let hasLocation = false
    if (img.location) {
      if (img.location.placename) {
        hasLocation = img.location.placename.length > 1
      }
    }
    let cls = [img.className]
    if (this.state.hover) {
      cls.push('over')
    }
    let wrapperClasses = cls.join(' ')

    let description = this.buildDescription()
    cls = ['heart']
    if (!hasLiked) {
      cls.push('outline')
    }
    let likeClassNames = cls.join(' ')
    return (
      <figure className={wrapperClasses}>
        <Icon className={likeClassNames} onClick={this.like}>
          {numLikes > 0 && <span className="num">{numLikes}</span>}
        </Icon>
        <Picture img={img} sizes={sizes} setHover={this.setHover} />
        <figcaption>
          <p className="caption">
            <span className="title">{img.caption}</span>
            {hasUser && (
              <span className="user-display-name right">
                <em className="label">{config.labels.photographer}</em>
                <span className="content">{img.user.displayName}</span>
              </span>
            )}
          </p>
          <p className="description">
            {hasDescription && <span className="text">{img.description}</span>}
            {hasLocation && (
              <p className="location">
                <em className="label">Location</em>
                <span className="content">{img.location.placename}</span>
              </p>
            )}
          </p>
          {img.tags.length > 0 && (
            <ul className="tags">
              {img.tags.map(t => (
                <li key={t._id}>{t.name}</li>
              ))}
            </ul>
          )}
          <SocialShare
            title={img.caption}
            path={`/gallery/${img._id}`}
            tags={img.tags}
            media={img.uri}
            description={description}
          />
        </figcaption>
      </figure>
    )
  }
}

export default MainPhoto

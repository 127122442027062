export default {
  //backendApi: 'http://localhost:3051/api/',
  backendApi: '/api/',
  apiKey: 'drW3-Pd_8H-92mBw5kM',
  localStoreEnabled: true,
  title: 'Gavin Turk - Portrait of an Egg',
  labels: {
    photographer: 'Photographer',
    thankyouUploading: "Thank you for uploading your portrait of an egg - it might take a day or so to  appear on the site because we need to check for broken ones!"
  },
  social: {
    fbAppID: '325042438113754',
    googleAppID:
      '193669454614-uhn4lg7pdi1hchc2em6fjbs2pjhpcirp.apps.googleusercontent.com',
    instagramAppID: 'e6c07deea78c4376a39930d4a45fcbbe'
  },
  cloudinary: {
    widget: {
      cloudName: 'portrait-of-an-egg',
      uploadPreset: 'contributor',
      sources: ['local', 'camera', 'facebook', 'dropbox', 'instagram'],
      minImageWidth: 639,
      minImageHeight: 440
    },
    baseUrl: 'https://res.cloudinary.com',
    quality: 86
  },
  gallery: {
    perLoad: 60
  },
  likes: {
    timeoutHours: 6
  },
  imageStyles: {
    large: {
      crop: 'fit',
      sizes: [
        {
          width: 640,
          height: 640,
          max: 640
        },
        {
          width: 1280,
          height: 1280,
          min: 641,
          max: 1280,
          default: true
        },
        {
          width: 1600,
          height: 1600,
          min: 1281
        }
      ]
    }
  },
  mainMenuItems: [
    { to: '/', content: 'Project' },
    { to: '/gallery', content: 'Gallery' },
    { to: '/hall-of-fame', content: 'Hall of Fame' },
    { to: '/news', content: 'News' },
    { to: '/about', content: 'Gavin Turk' },
    {
      to: '/contribute',
      label: 'Contribute',
      loggedinLabel: 'My Images',
      icon: 'picture'
    }
  ],
  footerData: {
    title: 'Info',
    subtitle: 'In collaboration with',
    menuItems: [
      { to: '/info/terms-and-conditions', content: 'Terms & Conditions' },
      { to: '/info/privacy-policy', content: 'Privacy Policy' },
      { to: '/info/acceptable-use-policy', content: 'Acceptable Use Policy' },
      { to: '/info/cookie-policy', content: 'Cookie Policy' },
      { to: '/info/licence', content: 'Licence' }
    ],
    logos: [
      { uri: '/images/photo-london-logo.svg', title: 'Photo London' },
      {
        uri: '/images/ben-brown-logo.png',
        title: 'Ben Brown'
      },
      {
        uri: '/images/somerset-house.svg',
        title: 'Somerset House'
      }
    ],
    creditLinks: [
      { to: 'http://www.geneclosuit.com', content: 'designed by gene.closuit' },
      { to: 'https://www.platform-3.co.uk', content: 'built by Platform3' }
    ]
  }
}
